import axios from "axios"

const basicurl = " https://backend.kast.biz/"

const token = localStorage.getItem("user_token")

export const UserEmailLoginService = async (data) => {
  let res = await axios.post(basicurl + "api/loginWithEmail", data)
  //.log("login res=>",res)
  return res
}

export const UserPhoneLoginService = async (data) => {
  let res = await axios.post(basicurl + "api/loginWithPhone", data)
  // //.log("login res=>",res)
  return res
}

export const UserSignupService = async (data) => {
  let res = await axios.post(basicurl + "api/saveUser", data)
  return res
}

export const AddVerifyemail = async (data) => {
  //.log(data)
  let res = await axios.get(basicurl + `api/sendVerifiedMail/${data} `, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const ViewLeadsService = async () => {
  let res = await axios.get(basicurl + "api/allVerifiedLead", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const AllVerifiedLeadsService = async () => {
  let res = await axios.get(basicurl + "api/allVerifiedLead", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const AllUnVerifiedLeadsService = async () => {
  let res = await axios.get(basicurl + "api/allUnVerifiedLead", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const ArchiveLeadsService = async () => {
  let res = await axios.get(basicurl + "api/getAllIs_archY", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const AddLeadService = async (data) => {
  let res = await axios.post(basicurl + "api/allUnVerifiedLead", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const AddNewLeadService = async (data) => {
  let res = await axios.post(basicurl + "api/addLead", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const UpdateLeadService = async (id, data) => {
  let res = await axios.put(basicurl + `api/updateLead/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const AddLeadFromBizoodaService = async (data) => {
  let res = await axios.post(basicurl + "api/addNewBizooda", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log(res)
  return res.data
}

export const DeleteLeadService = async (data) => {
  //.log(data)
  let res = await axios.delete(basicurl + `api/deleteLead/${data}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("delete res=>",res)
  return res
}

export const ImageUploadService = async (data) => {
  //.log(data)
  let res = await axios.post(basicurl + `api/uploadImage`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const UpdateLeadTypeService = async (data) => {
  //.log(data)
  let res = await axios.put(basicurl + `api/updateLead/${data.id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  //.log("lead type res=>",res)
  return res
}
// ---------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------


// getting all data
export const GetAllNurtureService = async () => {
  //.log("get all nuirture service")
  let res = await axios.get(basicurl + `api/getAllNurture`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  //.log("image res=>",res)
  return res
}

export const GetAllInsuranceAgentService = async () => {
  let res = await axios.get(basicurl + `api/getAllInsuranceAgent`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const GetAllClosingAgentService = async () => {
  let res = await axios.get(basicurl + `api/getAllClosingAgent`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const GetAllCompanyAttorneyService = async () => {
  let res = await axios.get(basicurl + `api/getAllCompanyAttorney`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const GetAllLoanAgentService = async () => {
  let res = await axios.get(basicurl + `api/getAllLoanAgent`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const GetAllAgentService = async () => {
  let res = await axios.get(basicurl + `api/getAllAgent`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const GetAllSourceService = async () => {
  let res = await axios.get(basicurl + `api/getAllSource`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const getAllSourceForLead = async (data) => {
  let res = await axios.post(basicurl + `api/getAllSourceWithouttoken`, data)
  // //.log("image res=>",res)
  return res
}

export const getUserIdFromPostcardURL = async (data) => {
  let res = await axios.get(basicurl + `api/getUserIdFromPostcardURL/` + data)
  // //.log("image res=>",res)
  return res
}

export const GetAllTagService = async () => {
  let res = await axios.get(basicurl + `api/getAllTag`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}


// edit data by id

export const GetLeadByIdService = async () => {
  let res = await axios.get(basicurl + `api/getLeadById/${localStorage.getItem("leadId")}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const AddTagService = async (data) => {
  //.log(data)
  let res = await axios.post(basicurl + `api/addTag`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const AddSourceService = async (data) => {
  //.log(data)
  let res = await axios.post(basicurl + `api/addSource`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}


// ///////////////////////////////////

export const CreateLeadWithoutTokenService = async (data) => {
  //.log(data)
  let res = await axios.post(basicurl + `api/createLeadWithoutToken`, data)
  // //.log("image res=>",res)
  return res
}


export const MakeLeadArchService = async (data) => {
  //.log("harsh data=>",data)
  let res = await axios.put(basicurl + `api/makeLeadArch/${data}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const AddLoanAgent = async (data) => {
  //.log(data)
  let res = await axios.post(basicurl + `api/addLoanAgent`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const AddInsuranceAgent = async (data) => {
  //.log(data)
  let res = await axios.post(basicurl + `api/addInsuranceAgent`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const AddCompanyAttorney = async (data) => {
  //.log(data)
  let res = await axios.post(basicurl + `api/addCompanyAttorney`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const CreateUser = async (data) => {
  //.log(data)
  let res = await axios.post(basicurl + `api/createUser`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}

export const GetDashBoardService = async () => {
  //.log(data)
  let res = await axios.get(basicurl + `api/getDashBoardData`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}


export const InsertBulkLeadService = async (data) => {
  //.log(data)
  let res = await axios.get(basicurl + `api/insertBulkLead`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  // //.log("image res=>",res)
  return res
}


export const InsertAdminFunnelAPI = async (data) => {
  let res = await axios.post(basicurl + `api/create-funnel`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const getUserList = async () => {
  console.log(basicurl + `/api/get-list-of-users`)
  let res = await axios.get(basicurl + `api/get-list-of-users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const addFunnelToUser = async (data) => {
  let res = await axios.post(basicurl + `api/add-funnel-for-user`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const getfunnelForUser = async (id, link) => {
  let res = await axios.get(basicurl + `api/get-funnel-for-user/${id}/${link}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}


export const getScriptByUserID = async (id) => {
  let res = await axios.get(basicurl + `api/get_script_by_id/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const getSubscribeSerive = async (id) => {
  let res = await axios.get(basicurl + `api/get-service-by-user-id/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

// 
export const getScriptByUser = async (id) => {
  try {

    let res = await axios.get(basicurl + `api/get_script_by_id/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return res
  } catch (err) {
    return { data: { script: [] } }
  }
}


export const checkValidationForKastIDAPI = async (id) => {
  try {
    const response = await axios.get(basicurl + "api/check-validity/" + id, { headers: { "Content-Type": "*" } })
    return response.data
  } catch (err) {
    console.log(err)
    return "";
  }
}