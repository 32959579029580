import { makeStyles } from '@mui/styles';
import { Container, Select, MenuItem, Button, Alert, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';

const basicurl = " https://backend.kast.biz/";


export const AddScript = () => {
    const [selectedOption, setSelectedOption] = useState('1');
    const [users, setUsers] = React.useState([]);
    const [script, setScript] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const snackMsg = "script added";
    const [severity, setSeverity] = useState("success")
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const getAllUser = async () => {
        const resp = await axios.get(basicurl + "api/get_user_details")
        setUsers(resp.data.data)
        setSelectedOption(resp.data.data[0].id);

    }

    React.useEffect(() => {
        getAllUser()
    }, [])

    const handleSubmit = async () => {
        if (script != "") {
            const res = await axios.post(basicurl + "api/add_script", {
                user_id: selectedOption,
                script: script
            })
            if (res.status == 200) {
                setOpen(true)
            } else {
                alert(res.data.error_message ?? "Erorr while added script");
            }
            setScript("");
        }
    }


    return <>
        <Select value={selectedOption} onChange={handleOptionChange} variant="outlined">
            {users.map((data, index) => {
                return <MenuItem key={index} value={data.id}>{data.name}</MenuItem>
            })}

        </Select>
        <label>{"enter your script below"}</label>
        <div style={{ flex: 1, flexDirection: "column" }}>
            <textarea onChange={(e) => setScript(e.target.value)} style={{ padding: 10, margin: 10, height: 200, width: 200 }}></textarea>
        </div>
        <Button variant='contained' onClick={handleSubmit}>Submit</Button>

        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} autoHideDuration={3000} sx={{ zIndex: 100 }}>
            <Alert severity={severity} sx={{ width: '100%' }}>
                {snackMsg}
            </Alert>
        </Snackbar>
    </>
}