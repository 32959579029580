import axios from "axios"

const basicurl = " https://backend.kast.biz/"

const token = localStorage.getItem("user_token")
//.log(token)

export const FetchCampaignService = async () => {
  let res = await axios.get(basicurl + "api/fetchCampaign", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const CreateCampaignService = async (data) => {
  let res = await axios.post(basicurl + "api/createCampaign", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const DeleteCampaignService = async (data) => {
  //  //.log(data)
  let res = await axios.delete(basicurl + `api/deleteCampaign/${data}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const AddUserToCampaignService = async (data) => {
  let res = await axios.post(basicurl + "api/createCampaign", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const GetCampaignUserService = async () => {
  let res = await axios.get(basicurl + "api/getCampaignUser", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const GetNameAndIdService = async () => {
  let res = await axios.get(basicurl + "api/getNameAndId", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const GetCampaignActivityByCampaignIdService = async (data) => {
  let res = await axios.get(basicurl + `api/getCampaignActivityByCampaignId/${data}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const AddUsertoCompaignService = async (data) => {
  let res = await axios.post(basicurl + `api/addUsertoCompaign`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const CreateCampaignActivityService = async (data) => {
  let res = await axios.post(basicurl + "api/createCampaignActivity", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const GetCardByIdService = async (data) => {
  let res = await axios.get(` https://backend.kast.biz/api/getCartById/${data}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const GetCardByNameService = async (data) => {
  let res = await axios.get(` https://backend.kast.biz/api/getCartByKast_name/${data}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const getFunnelDetailsByAPI = async (id) => {
  let res = await axios.get(basicurl + `api/getAllFunnel/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const getUserListingByKastnameAPI = async (kast_name) => {
  // console.log(basicurl + `/api/getAllFunnel/${kast_name}`)
  let res = await axios.get(basicurl + `api/get-user-listing-by-kast-name/${kast_name}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}


export const sendNotificationAPI = async (id) => {
  let res = await axios.post(basicurl + `api/sendNotificationById`, {
    userId: id,
    title: "New Visitor 🙌",
    body: "Someone is on your Kast"
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const sendNotificationForPostcardAPI = async (id, PostcardName) => {
  let res = await axios.post(basicurl + `api/sendNotificationById`, {
    userId: id,
    title: PostcardName + " Postcard Scanned! 🙌",
    body: "Someone scanned your postcard "
    // QR Scan add this message with postcard name
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const getSourceNameformListingAPI = async (id) => {

  let res = await axios.get(basicurl + `api/get-source-by-type-and-id/L/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}

export const getSourceNameformFunnelAPI = async (id) => {

  let res = await axios.get(basicurl + `api/get-source-by-type-and-id/F/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}


export const getVcfFileLink = async (id) => {

  let res = await axios.get(basicurl + `api/get-vcf-file-by-kastId/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return res
}