import axios from "axios";

const basicurl = " https://backend.kast.biz/";

const token = localStorage.getItem("user_token");

export const ALlTaskService = async (data) => {
  let res = await axios.get(basicurl + "api/getAllTask", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const AddTaskService = async (data) => {
  let res = await axios.post(basicurl + "api/addNewTask", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const GetDueDatesService = async () => {
  let res = await axios.get(basicurl + "api/getDueDates", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const AddTaskServicey = async (data) => {
  let res = await axios.post(basicurl + "api/addNewTask", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const GetTaskBasedOnDueDateTaskTypeService = async (data) => {
  let res = await axios.get(
    basicurl + `api/getTaskBasedOnDueDateTaskType/${data}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const GetUserListService = async () => {
  let res = await axios.get(basicurl + "api/getUserList", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  //  //.log(res)
  return res;
};
