import axios from "axios"


const basicurl = " https://backend.kast.biz/"

const token = localStorage.getItem("user_token")

export const getListingDetailsAPI = async (id) => {
    let res = await axios.get(basicurl + "api/get-listing-by-title/" + id, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return res
}
// 

export const getProfileById = async (id) => {
    let res = await axios.get(basicurl + "api/get-profile-by-userId-without-token/" + id, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return res
}

// MLS_number: null

// address: "Bkjb"

// amenities: null

// bathroom: "1"

// bedrooms: "1"

// created_at: "2023-10-02T10:09:24.000000Z"

// created_by: 1

// date: null

// description: "Jhbdfjh"

// hoa_fees: null

// holding_open_house: "2"

// id: 17

// image: "https://bizooda.s3.us-east-2.amazonaws.com/folder_name/rwRF40pM8x8o9DSqvlqgY3SMcFjrYvUtShL1KAs7.jpg,https://bizooda.s3.us-east-2.amazonaws.c…"

// listing_title: "Kbkjk"

// living_space: "8954"

// other_amenities: null

// property_id: null

// read_estate_type: null

// sales_prices: "230000"

// status: null

// time_fram: null

// updated_at: "2023-10-02T10:09:24.000000Z"

// year_built: "2020"